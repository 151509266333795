<template>
  <f7-page>
    <f7-navbar title="E秒贷" back-link="  " sliding>
      <f7-nav-right>
        <f7-link icon="icon-bars" open-panel="left"></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <div class="bg">

      <!-- 上部背景图片 -->
      <div class="top-image-bg">
        <p><img class="imgBox-bg" src="../assets/bank/gf/gfbg.png" /></p>
      </div>

      <!-- 广发银行“E秒贷” -->
      <div class="bottom-content">
        <f7-row no-gap>
          <f7-col width="50">
            <p class="detail-title">广发银行“E秒贷”</p>
            <p style="margin: 0;">个人信用消费贷款产品，年利率4.8%起。</p>
          </f7-col>
          <f7-col width="50">
            <p><img class="nameBox-bg" src="../assets/bank/gf/namebg.jpg" /></p>
          </f7-col>
        </f7-row>
        
      </div>

      <!-- 申请条件 -->
      <div class="condition-box">
        <p><f7-chip outline text="申请条件"></f7-chip></p>
        <p><f7-chip text="我国25-55周岁的大陆公民" media-bg-color="white" media-text-color="#BE0017;" media="➀"></f7-chip></p>
        <p><f7-chip text="有公积金、社保、个税或我行代发工资记录" media-bg-color="white" media-text-color="#BE0017" media="➁"></f7-chip></p>
        <p><f7-chip text="有稳定工作收入的工薪人士" media-bg-color="white" media-text-color="#BE0017" media="➂"></f7-chip></p>
        <p><f7-chip text="有良好的信用记录" media-bg-color="white" media-text-color="#BE0017" media="➃"></f7-chip></p>
      </div>

      <!-- 申请流程 -->
      <!-- <div class="condition-box">
        <p><f7-chip outline text="申请流程" color="red"></f7-chip></p>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5" style="font-size: 12px;text-align: center; font-weight: bold; color: #BE0017;">•</f7-col>
          <f7-col width="95" style="font-size: 12px;font-weight: bold; color: #BE0017;">持有我行借记卡用户：请下载手机银行APP，快速申请及提款。</f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5"></f7-col>
          <f7-col width="95"><f7-chip text="登录" color="#BE0017"></f7-chip><span style="color: #BE0017;"> ➞ </span><f7-chip text="贷款" color="#BE0017"></f7-chip><span style="color: #BE0017;"> ➞ </span><f7-chip text="E秒贷【贷款申请】" color="#BE0017"></f7-chip></f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5" style="font-size: 12px;text-align: center; font-weight: bold; color: #BE0017;">•</f7-col>
          <f7-col width="95" style="font-size: 12px;font-weight: bold; color: #BE0017;">未持有我行借记卡客户：请点击下方申请按钮，快速申请及提款。</f7-col>
        </f7-row>
      </div> -->

      <!-- 补充 -->
      <div class="addition-box">
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5" style="font-size: 12px;text-align: center;">•</f7-col>
          <f7-col width="95" style="font-size: 12px;">贷款利率为年化利率，采用单利法计算。</f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5" style="font-size: 12px;text-align: center;">•</f7-col>
          <f7-col width="95" style="font-size: 12px;">E秒贷全线上申请，额度最高20万，符合条件客户可线下补充材料进行提额，详询当地广发银行或致电4008308003。</f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5" style="font-size: 12px;text-align: center;">•</f7-col>
          <f7-col width="95" style="font-size: 12px;">个人信用贷款资金不可用于购买房产、股票、期货、理财产品等其他权益投资及法律规定禁止的用途。</f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5" style="font-size: 12px;text-align: center;">•</f7-col>
          <f7-col width="95" style="font-size: 12px;">个人信用贷款批核情况及贷款金额、利率和期限，以我行最终合同为准。</f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5" style="font-size: 12px;text-align: center;">•</f7-col>
          <f7-col width="95" style="font-size: 12px;">在符合监管规定的前提下，广发银行有权根据业务发展及市场情况调整上述产品政策内容。</f7-col>
        </f7-row>
      </div>

      <!-- 电话网址 -->
      <div class="phone">
        <p>客服热线：400-830-8003    www.cgbchina.com.cn</p>
      </div>

      <!-- 底部按钮 -->
      <div class="buttonSublit btnAnimation">
        <a onclick="_hmt.push(['_trackEvent', 'msBank_external_link_apply_loan', 'click'])">
          <f7-button fill round large href='http://95508.com/vgg4UtKox' external>
            立即申请
          </f7-button>
        </a>
      </div>
    </div>
    
  </f7-page>
</template>


<style lang="less" scoped>
.bg {
  background: white;
  left:0%;
  right:0%;
  top:0%;
  bottom: 0%;
  padding-bottom: 15px;
}
.imgBox-bg {
  overflow: hidden;
  width: 100%;
}
.top-image-bg {
  >p{
  margin: 0;
  width: 100%;
}
}
.bottom-content {
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
}
.detail-title {
  color: #BE0017;
  font-size: 15px;
  font-weight: bold;
  margin: 0;
}
.nameBox-bg {
  width: 100%;
}
.chip {
  --f7-chip-font-size: 10px;
  --f7-chip-font-weight: bold;
}
.chip-outline {
  --f7-chip-outline-border-color: #BE0017;
  --f7-chip-text-color: #BE0017;
}
.condition-box {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  >p {
    margin: 0;
  }
}
.addition-box {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.phone {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  color: #BE0017;
}

.button-fill {
  background: -prefix-linear-gradient(left, rgb(200,25,46), #FFB6C1); 
  background: linear-gradient(to right, rgb(200,25,46), #FFB6C1);
}
.button-large {
  --f7-button-large-font-size: 25px;
}
</style>